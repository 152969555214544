<template>
  <div v-if="!isEstimated">
    <transition name="fade" mode="out-in">
      <div v-if="!isProductEstimated" key="product-rate" class="card-estimate">
        <div class="card-estimate__title">
          {{ $t("parcelCardRateProduct") }}
        </div>
        <star-rating :value.sync="productStars" :onSet="onRateProduct" />
      </div>

      <div
        v-if="isProductEstimated && !isDeliveryEstimated"
        key="delivery-rate"
        class="card-estimate"
      >
        <div class="card-estimate__title">
          {{ $t("parcelCardRateDelivery") }}
        </div>
        <star-rating :value.sync="deliveryStars" :onSet="onRateDelivery" />
      </div>
    </transition>

    <easy-bottom-sheet
      :isOpened.sync="isProductQuestionOpened"
      :title="$t('parcelCardProductFeedbackTitle')"
      key="parcelCardProductFeedbackTitle"
    >
      <easy-input
        v-model="productFeedback"
        :label="$t('parcelCardFeedbackValue')"
      ></easy-input>

      <template v-slot:footer>
        <button
          @click="onProductSubmit"
          class="btnWideRound"
          :disabled="!productFeedback"
          :class="{ isLoading: isLoading }"
        >
          {{ $t("btnDone") }}
        </button>
      </template>
    </easy-bottom-sheet>
    <easy-bottom-sheet
      :isOpened.sync="isDeliveryQuestionOpened"
      :title="$t('parcelCardDeliveryFeedbackTitle')"
    >
      <easy-input
        v-model="deliveryFeedback"
        :label="$t('parcelCardFeedbackValue')"
      ></easy-input>

      <template v-slot:footer>
        <button
          @click="onDeliverySubmit"
          class="btnWideRound"
          :disabled="!deliveryFeedback"
          :class="{ isLoading: isLoading }"
        >
          {{ $t("btnDone") }}
        </button>
      </template>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import StarRating from "@/components/StarRating";
import EasyBottomSheet from "@/components/common/EasyBottomSheet";
import EasyInput from "@/components/common/EasyInput";
import { saveEstimate } from "@/services/requests-list";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "CardEstimate",
  components: { StarRating, EasyBottomSheet, EasyInput },
  props: ["parcelId", "shareId", "isBuyout", "isDisabledProduct", "productRate", "deliveryRate"],
  data() {
    return {
      productStars: 0,
      deliveryStars: 0,
      isProductQuestionOpened: false,
      productFeedback: "",
      isDeliveryEstimated: false,
      isDeliveryQuestionOpened: false,
      deliveryFeedback: "",

      isLoading: false,
      isShareBlocked: window.localStorage.getItem(`isShareRequestBlocked`),
    };
  },
  computed: {
    isEstimated() {
      return Boolean(this.deliveryRate)
    },
    isProductEstimated() {
      return this.isDisabledProduct || Boolean(this.productRate)
    },
  },
  methods: {
    ...mapActions("parcels", ["toggleShareParcel"]),
    ...mapMutations("parcels", ["updateParcel"]),
    onRateProduct(rating) {
      if (rating < 4) {
        this.isProductQuestionOpened = true;
      } else {
        this.onProductSubmit();
      }
    },
    async onProductSubmit() {
      if (this.isLoading) return false;
      this.isLoading = true;
      await saveEstimate({
        id: this.parcelId,
        text: this.productFeedback || null,
        type: "product",
        rate: this.productStars,
      });
      this.updateParcel({
        id: this.shareId,
        Rate_11834: this.productStars,
      })
      this.isLoading = false;
      this.isProductQuestionOpened = false;
      this.isProductEstimated = true;

      if (this.productStars < 4) {
        return this.showThxPopup(this.productStars);
      }
      if (!this.isShareBlocked) {
        this.$root.$emit("shared-popup", this.shareId);
      } else {
        await this.toggleShareParcel({
          id: this.shareId,
          value: false,
        });
      }
    },
    onRateDelivery(rating) {
      if (rating < 4) {
        this.isDeliveryQuestionOpened = true;
      } else {
        this.onDeliverySubmit();
      }
    },
    async onDeliverySubmit() {
      if (this.isLoading) return false;
      this.isLoading = true;
      await saveEstimate({
        id: this.parcelId,
        text: this.deliveryFeedback,
        type: "delivery",
        rate: this.deliveryStars,
      });
      this.showThxPopup(this.deliveryStars);
      this.isLoading = false;
      this.isDeliveryQuestionOpened = false;
      this.isDeliveryEstimated = true;
      window.localStorage.setItem(`isEstimated${this.parcelId}`, "true");
    },
    showThxPopup(rating) {
      console.log("RATING", rating);
      if (rating >= 4) {
        this.$root.$emit("notify-popup", {
          title: this.$t("estimateThxTitle"),
          message: this.$t("estimateThxText"),
          image: "notify-popup-base.svg",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card-estimate {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #f1f1f1;

  &__title {
    margin-bottom: 4px;
  }
}
</style>