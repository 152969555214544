<template>
  <transition name="fade">
    <div v-if="!isHidden" class="push-parcel__container" key="push-parcel">
      <div class="push-parcel" v-touch:tap.prevent="onOpen">
        <div class="push-parcel__img">
          <lottie :options="defaultOptions" :height="152" :width="182" />
        </div>
        <div class="push-parcel__info">
          <div class="push-parcel__title">{{ $t("refPushParcelTitle") }}</div>
          <div class="push-parcel__text">
            {{ $t("refPushParcelText", { val: refferalRate }) }}
          </div>
        </div>
      </div>
      <button v-touch:tap.prevent="onOpen" class="btnMain green">
        {{ $t("refPushParcelBtn") }}
      </button>
      <div @click="onClose" class="push-parcel__close">
        <img src="@/assets/img/close-w.svg" />
      </div>
    </div>
  </transition>
</template>

<script>
import Lottie from "vue-lottie";
import animationData from "@/assets/animation/data.json";
import { mapGetters } from "vuex";

export default {
  name: "PushParcel",
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: { animationData: animationData, loop: false },
      isHidden: localStorage.getItem("parcelPushDisabled") === "true",
    };
  },
  computed: {
    ...mapGetters("root", ["refferalRate"]),
  },
  methods: {
    onClose() {
      localStorage.setItem("parcelPushDisabled", "true");
      this.isHidden = true;
    },
    onOpen() {
      this.$router.push("refferal");
    },
  },
};
</script>

<style lang="scss">
.push-parcel {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
}
.push-parcel__info {
  flex: 1 auto;
}
.push-parcel__info span {
  display: block;
  font-size: 14px;
  color: #696969;
  line-height: 17px;
  margin-top: 2px;
}
.push-parcel__info span b {
  color: #91c258;
}
.push-parcel__container {
  position: relative;
  margin-bottom: 24px;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  z-index: 4;
  padding: 16px;
  padding-top: 24px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;

  .btnMain {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}
.push-parcel__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: absolute;
  top: -60px;
  left: 14px;
  z-index: 2;
}
.push-parcel__img svg {
  display: block;
  width: 182px;
  height: 152px;
}
.push-parcel__title {
  color: #282828;
  font-size: 16px;
  font-family: "Helvetica Neue Medium";
  line-height: 18px;
  margin-bottom: 6px;
}
.push-parcel__text {
  color: #696969;
  font-size: 14px;
  line-height: 1.3;
}
.push-parcel__close {
  background: #fff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  animation: fadein 0.3s ease-in forwards;
  animation-delay: 3s;
  opacity: 0;
}
.push-parcel__close img {
  display: block;
  filter: invert(100);
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
