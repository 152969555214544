<template>
  <div class="star-rating">
    <label
      class="star-rating__star"
      v-for="(rating, i) in ratings"
      :class="{
        'is-selected': value >= rating && value != null,
        'is-disabled': disabled,
      }"
      :key="i"
      v-touch:tap.stop="() => onTap(rating)"
    >
      <input
        class="star-rating star-rating__checkbox"
        type="radio"
        :value="rating"
        :name="name"
        v-model="value"
        :disabled="disabled"
      />
      <svg
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.125 10.118H17.0822L14 0.75L10.9178 10.118H0.875L9.08961 15.882L5.89039 25.25L14 19.4444L22.1096 25.25L18.9044 15.882L27.125 10.118Z"
        />
      </svg>
    </label>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    name: String,
    value: null,
    id: String,
    disabled: Boolean,
    required: Boolean,
    onSet: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      temp_value: null,
      ratings: [1, 2, 3, 4, 5],
    };
  },
  methods: {
    onTap(value) {
      if (!this.disabled) {
        this.temp_value = value;
        this.$emit("update:value", value);
        this.onSet(value)
      }
    },
  },
};
</script>

<style lang="scss">
%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.star-rating {
  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;

    svg {
      fill: #ababab;
      transition: color 0.2s ease-out;
    }

    &:hover {
      cursor: pointer;
    }

    &.is-selected svg {
      fill: #91c258;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}
</style>