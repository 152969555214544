























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Prop } from "vue/types/options";

import EasyBottomSheet from "./common/EasyBottomSheet.vue";
import { mapGetters } from "vuex";
import eventsRegister from "@/services/events-register";
import { svgShopping, svgParcel } from "@/services/svg";
import {
  copyToClipboard,
  generateDynamicLink,
  getNumEnding,
  logEventFA,
  logEventFB,
  vibrate,
} from "../utils/utils";
import { createRequest } from "../services/api-connector";
import CardEstimate from "./CardEstimate.vue";

const apiURL = process.env.VUE_APP_API_URL; // https://dev-wp.utec-l.com:9005
const imgFolderPath = apiURL + "/media/";

const dateSVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M18.3392 8.34352C18.1616 8.1575 18.0625 7.91021 18.0625 7.65304V3.67857C18.0625 3.12629 17.6148 2.67857 17.0625 2.67857H15.5212C15.1125 2.67857 14.7812 3.00986 14.7812 3.41853C14.7812 4.07092 13.9983 4.40407 13.5282 3.95171L12.1959 2.66969C11.8077 2.29612 11.1934 2.29725 10.8065 2.67225L2.34135 10.8783C1.85312 11.3516 2.18817 12.1786 2.86814 12.1786C3.28614 12.1786 3.625 12.5174 3.625 12.9354V20C3.625 20.5523 4.07272 21 4.625 21H8.53125C9.08353 21 9.53125 20.5523 9.53125 20V16.183C9.53125 15.0957 10.4127 14.2143 11.5 14.2143C12.5873 14.2143 13.4688 15.0957 13.4688 16.183V20C13.4688 20.5523 13.9165 21 14.4688 21H18.375C18.9273 21 19.375 20.5523 19.375 20V12.9652C19.375 12.5308 19.7272 12.1786 20.1616 12.1786C20.8537 12.1786 21.2085 11.3494 20.7306 10.8488L18.3392 8.34352Z" fill="#BFBFBF"/>
<path d="M23.5 11.5C23.5 14.2614 21.2614 16.5 18.5 16.5C15.7386 16.5 13.5 14.2614 13.5 11.5C13.5 8.73858 15.7386 6.5 18.5 6.5C21.2614 6.5 23.5 8.73858 23.5 11.5Z" fill="#BFBFBF" stroke="white"/>
<line x1="18.3536" y1="11.6464" x2="20.3536" y2="13.6464" stroke="white"/>
<line x1="18.5" y1="12" x2="18.5" y2="9" stroke="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H24V24H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`;

const shopBusketSVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.100098 4.28211C0.100098 3.78505 0.503041 3.38211 1.0001 3.38211H3.0001C3.40595 3.38211 3.7616 3.65375 3.86839 4.0453L4.51773 6.42623L19.0593 7.38406C19.5323 7.41522 19.9001 7.80802 19.9001 8.28211V14.2821C19.9001 14.7524 19.538 15.1434 19.0691 15.1795L6.06913 16.1795C5.6387 16.2126 5.2454 15.9354 5.13181 15.5189L2.94999 7.51892L2.31268 5.18211H1.0001C0.503041 5.18211 0.100098 4.77917 0.100098 4.28211ZM7.87322 9.06383C7.75267 8.58161 7.26403 8.28843 6.78181 8.40898C6.2996 8.52954 6.00641 9.01818 6.12697 9.50039L7.12697 13.5004C7.24752 13.9826 7.73616 14.2758 8.21838 14.1552C8.70059 14.0347 8.99378 13.546 8.87322 13.0638L7.87322 9.06383ZM11.3728 8.64115C11.8649 8.57086 12.3208 8.91277 12.3911 9.40483L12.8911 12.9048C12.9613 13.3969 12.6194 13.8528 12.1274 13.9231C11.6353 13.9934 11.1794 13.6514 11.1091 13.1594L10.6091 9.65939C10.5389 9.16733 10.8808 8.71145 11.3728 8.64115ZM16.9001 9.78211C16.9001 9.28505 16.4972 8.88211 16.0001 8.88211C15.503 8.88211 15.1001 9.28505 15.1001 9.78211V12.7821C15.1001 13.2792 15.503 13.6821 16.0001 13.6821C16.4972 13.6821 16.9001 13.2792 16.9001 12.7821V9.78211ZM10.0001 19.2821C10.0001 20.3867 9.10467 21.2821 8.0001 21.2821C6.89554 21.2821 6.0001 20.3867 6.0001 19.2821C6.0001 18.1775 6.89554 17.2821 8.0001 17.2821C9.10467 17.2821 10.0001 18.1775 10.0001 19.2821ZM16.0001 21.2821C17.1047 21.2821 18.0001 20.3867 18.0001 19.2821C18.0001 18.1775 17.1047 17.2821 16.0001 17.2821C14.8955 17.2821 14.0001 18.1775 14.0001 19.2821C14.0001 20.3867 14.8955 21.2821 16.0001 21.2821Z"/>
</svg>`;

const truckSVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 18.2H3.25109C2.56013 18.2 2 17.6662 2 17.0053V6.19469C2 5.53488 2.56139 5 3.24425 5H13.2558C13.9429 5 14.5 5.53634 14.5 6.20293V8H17.9431C18.1126 8 18.3354 8.10244 18.4463 8.2356L21.8037 12.2644C21.9121 12.3945 22 12.6298 22 12.7984V17.0051C22 17.665 21.4442 18.2 20.7489 18.2H19.5C19.5 16.8745 18.3807 15.8 17 15.8C15.6193 15.8 14.5 16.8745 14.5 18.2H9.5C9.5 16.8745 8.38071 15.8 7 15.8C5.61929 15.8 4.5 16.8745 4.5 18.2ZM14.5 9.2V12.8H20.75L17.625 9.2H14.5ZM17 20C15.9645 20 15.125 19.1941 15.125 18.2C15.125 17.2059 15.9645 16.4 17 16.4C18.0355 16.4 18.875 17.2059 18.875 18.2C18.875 19.1941 18.0355 20 17 20ZM7 20C5.96447 20 5.125 19.1941 5.125 18.2C5.125 17.2059 5.96447 16.4 7 16.4C8.03553 16.4 8.875 17.2059 8.875 18.2C8.875 19.1941 8.03553 20 7 20Z"/>
</svg>`;

const parcelsSVG = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip45154)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.3854 2.88121C23.5283 2.34774 23.2117 1.79941 22.6783 1.65646L21.7123 1.39765C20.1119 0.96882 18.4669 1.91857 18.0381 3.51897L14.8997 15.2318C13.4483 15.2175 12.093 15.9999 11.3798 17.264L2.08175 14.7726C1.54829 14.6297 0.999948 14.9463 0.857006 15.4797C0.714065 16.0132 1.03065 16.5615 1.56411 16.7045L10.8621 19.1959C10.8445 20.9772 12.0273 22.6139 13.8277 23.0963C15.9615 23.6681 18.1549 22.4017 18.7266 20.2679C19.209 18.4675 18.383 16.6249 16.8315 15.7494L19.97 4.0366C20.1129 3.50314 20.6612 3.18655 21.1947 3.3295L22.1606 3.58832C22.6941 3.73126 23.2424 3.41468 23.3854 2.88121ZM15.3806 17.3007C14.3136 17.0149 13.217 17.648 12.9311 18.715C12.6452 19.7819 13.2784 20.8786 14.3453 21.1644C15.4122 21.4503 16.5089 20.8172 16.7948 19.7502C17.0807 18.6833 16.4475 17.5866 15.3806 17.3007Z"/>
<path d="M5.67243 3.69866L9.34099 4.68165L8.76615 6.82699C8.72782 6.97001 8.81181 7.11515 8.95511 7.15355L10.6747 7.61432C10.818 7.65272 10.9633 7.56902 11.0017 7.426L11.5765 5.28066L15.2164 6.25596C15.7323 6.39419 16.0262 6.90217 15.8883 7.41705L13.6349 15.8268C13.4969 16.3417 12.9884 16.6346 12.4725 16.4964L2.92852 13.9391C2.41263 13.8009 2.11868 13.2929 2.25665 12.778L4.51003 4.36826C4.64799 3.85338 5.18521 3.56811 5.67243 3.69866ZM9.53138 11.0789C9.55804 11.2087 9.62869 11.2889 9.74334 11.3197L10.4025 11.4963L9.81236 13.6988C9.78937 13.7847 9.83136 13.8572 9.91734 13.8803L10.6625 14.0799C10.7485 14.103 10.8211 14.0611 10.8441 13.9753L11.4343 11.7728L12.0935 11.9494C12.2081 11.9801 12.3095 11.9459 12.3974 11.8469C12.4854 11.7478 12.4874 11.6257 12.4531 11.5246L11.6842 9.69365C11.6422 9.62108 11.5716 9.54083 11.4856 9.51779C11.3996 9.49475 11.306 9.50032 11.2257 9.57077L9.64435 10.772C9.55637 10.871 9.49705 10.9777 9.53138 11.0789ZM5.31827 9.95002C5.34494 10.0798 5.41559 10.16 5.53023 10.1908L6.18942 10.3674L5.59925 12.5699C5.57626 12.6558 5.61825 12.7283 5.70423 12.7514L6.47807 12.9587C6.56405 12.9818 6.6367 12.9399 6.6597 12.8541L7.24987 10.6515L7.90906 10.8282C8.0237 10.8589 8.12501 10.8247 8.21299 10.7257C8.30097 10.6266 8.30297 10.5045 8.26864 10.4033L7.49978 8.57243C7.45779 8.49986 7.38714 8.41961 7.30115 8.39657C7.21517 8.37353 7.12153 8.3791 7.04121 8.44956L5.45991 9.65073C5.34326 9.74211 5.31261 9.85653 5.31827 9.95002Z"/>
</g>
<defs>
<clipPath id="clip45154">
<path d="M24 0H0V24H24V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`;

const airplaneSVG = `<svg class="airplaneIco" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.12519 10.1249L12.3638 10.1249L9.67887 2.74129C9.58043 2.47058 9.73383 2.24992 10.022 2.24992L11.2283 2.24992C11.5094 2.24992 11.8766 2.4485 12.0325 2.69347L16.7616 10.1249L18.8752 10.1249C20.1337 10.127 22.3032 10.5529 22.2375 11.9872C22.1718 13.4215 20.1863 13.9303 18.9282 13.9279L16.733 13.9198L12.0325 21.3064C11.8766 21.5513 11.5094 21.7499 11.2283 21.7499L10.022 21.7499C9.73383 21.7499 9.58043 21.5293 9.67887 21.2585L12.3534 13.9035L6.11966 13.8805L4.62346 15.3766C4.41731 15.5828 4.04207 15.659 3.76931 15.5398L3.39909 15.378C3.13351 15.262 3.014 14.9401 3.12633 14.6729L4.25019 11.9999L2.98456 9.46867C2.85807 9.21567 2.96268 8.89367 3.2248 8.76261L3.77558 8.48722C4.04162 8.3542 4.41731 8.41704 4.62346 8.62319L6.12519 10.1249Z" fill="#91C258"/>
</svg>`;

const houseSVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3392 8.34352C18.1616 8.1575 18.0625 7.91021 18.0625 7.65304V3.67857C18.0625 3.12629 17.6148 2.67857 17.0625 2.67857H15.5212C15.1125 2.67857 14.7812 3.00986 14.7812 3.41853C14.7812 4.07092 13.9983 4.40407 13.5282 3.95171L12.1959 2.66969C11.8077 2.29612 11.1934 2.29725 10.8065 2.67225L2.34135 10.8783C1.85312 11.3516 2.18817 12.1786 2.86814 12.1786C3.28614 12.1786 3.625 12.5174 3.625 12.9354V20C3.625 20.5523 4.07272 21 4.625 21H8.53125C9.08353 21 9.53125 20.5523 9.53125 20V16.183C9.53125 15.0957 10.4127 14.2143 11.5 14.2143C12.5873 14.2143 13.4688 15.0957 13.4688 16.183V20C13.4688 20.5523 13.9165 21 14.4688 21H18.375C18.9273 21 19.375 20.5523 19.375 20V12.9652C19.375 12.5308 19.7272 12.1786 20.1616 12.1786C20.8537 12.1786 21.2085 11.3494 20.7306 10.8488L18.3392 8.34352Z" fill="#BFBFBF"/>
</svg>`;

export default Vue.extend({
  props: {
    parcel: Object as Prop<any>,
    customer: Object as Prop<any>,
    isHasCombineParcels: Object as Prop<any>,
  },
  components: {
    EasyBottomSheet,
    CardEstimate,
  },
  data() {
    return {
      eventsRegister,
      svgShopping,
      svgParcel,
      dateSVG,
      shopBusketSVG,
      truckSVG,
      parcelsSVG,
      airplaneSVG,
      houseSVG,
      logEventFA,
      logEventFB,
      cardIsSwiped: false,
      parcelLoading: false,
      isDeclinedOpened: false,
      isActionPanelOpened: false,
      touchOptions: {
        touchClass: "active",
        tapTolerance: 1,
        disableClick: false,
      },
      longTapOccurred: false,
    };
  },
  created() {
    console.log("parcel info", this.parcel);
  },
  computed: {
    ...mapGetters("root", ["refferalRate"]),
    isCanBeArchived(): boolean {
      const { State_id, p_State_id } = this.parcel;
      return (
        [5006, -5004, 5019, 5013].includes(p_State_id) ||
        [5013, 5029].includes(State_id)
      );
    },
    isCanBeDeleted(): boolean {
      const { State_id, p_State_id } = this.parcel;
      return (
        [5006, -5004, 5001].includes(p_State_id) ||
        [5013, -5004, 5006].includes(State_id)
      );
    },
    isBuyout(): boolean {
      return !!this.parcel.iParcel_id;
    },
    isCanBeCombined(): boolean {
      const { State_id, p_State_id, pr_587 } = this.parcel;
      if (!this.isHasCombineParcels[pr_587]) return false;

      return [5020].includes(p_State_id) || [5020].includes(State_id);
    },
    arrivedDaysCount(): string {
      const { QtyDayInWay = 0 } = this.parcel;
      return getNumEnding(QtyDayInWay, [
        this.$t("dayPC") as string,
        this.$t("days3PC") as string,
        this.$t("daysPC") as string,
      ]);
    },
  },
  methods: {
    onExtendCard() {
      if (!this.isBuyout && !this.isCanBeArchived && !this.isCanBeDeleted)
        return false;
      this.isActionPanelOpened = true;
      vibrate(50);
    },
    formatDate(dateStr: string): string {
      const date = new Date(dateStr);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      return day + "." + month + "." + date.getFullYear();
    },

    getDeliveryDays(): string {
      const parcel: any = this.parcel;
      let toReturn: any = parcel.Qty_day_min ? parcel.Qty_day_min : "";
      toReturn += " " + this.$t("daysPC");

      return `${this.$t("fromAcc")} ${toReturn}`.toLowerCase();
    },
    getDeliveryDates(parcel: any): string {
      const dateMin = new Date(parcel.D_min);
      const dayMin = ("0" + dateMin.getDate()).slice(-2);
      const monthMin = ("0" + (dateMin.getMonth() + 1)).slice(-2);

      let toReturn = "";
      if (parcel.D_min) toReturn = dayMin + "." + monthMin;
      if (parcel.nShift && parcel.nShift > 0) toReturn = "";

      if (parcel.D_max) {
        const dateMax = new Date(parcel.D_max);
        const dayMax = ("0" + dateMax.getDate()).slice(-2);
        const monthMax = ("0" + (dateMax.getMonth() + 1)).slice(-2);
        if (toReturn) toReturn += " - " + dayMax + "." + monthMax;
        else toReturn = dayMax + "." + monthMax;
      }

      return toReturn;
    },
    getDaysShift(parcel: any): string {
      if (parcel.nShift && parcel.nShift > 0) {
        let days = this.$t("daysPC");
        if (parcel.nShift > 4 && parcel.nShift < 21) days = this.$t("daysPC");
        // 5-20 days
        else if (parcel.nShift % 10 === 1) days = this.$t("dayPC");
        // 1, 11, 12 .... except 11
        else if (parcel.nShift % 10 > 1 && parcel.nShift % 10 < 5)
          days = this.$t("days3PC"); // 2,3,4, 22,23,24, 32,33,34 except 11

        return " +" + parcel.nShift + " " + days;
      } else return "";
    },

    getDateFromTo(parcel: any): string {
      // get dateFrom - dateTo string. Ignore dateFrom year if dateFrom year and dateTo year are the same
      const dateStart = new Date(parcel.pr492);
      const dayStart = ("0" + dateStart.getDate()).slice(-2);
      const monthStart = ("0" + (dateStart.getMonth() + 1)).slice(-2);
      const dateStartDayMonth = dayStart + "." + monthStart;

      const dateEnd = new Date(parcel.pr492_2);
      const dayEnd = ("0" + dateEnd.getDate()).slice(-2);
      const monthEnd = ("0" + (dateEnd.getMonth() + 1)).slice(-2);

      // if (dateStart.getFullYear() != dateEnd.getFullYear())
      // dateStartDayMonth += "." + dateStart.getFullYear();

      return (
        dateStartDayMonth +
        " - " +
        dayEnd +
        "." +
        monthEnd +
        "." +
        dateEnd.getFullYear()
      );
    },

    getImgPrevPathStyle(imgPath: string): string {
      // imgPath: 'objectlist/2401390/2401390_19-02-12_15-54-40.Jpeg'
      const dotIdx = imgPath.lastIndexOf(".");
      const imgPathOnly = imgPath.substring(0, dotIdx); // objectlist/2401390/2401390_19-02-12_15-54-40
      const imgExt = imgPath.substring(dotIdx + 1); // 'jpg'
      return 'url("' + imgFolderPath + imgPathOnly + "_256." + imgExt + '")';
    },

    parcelClicked(e: any): any {
      if (this.longTapOccurred) {
        this.longTapOccurred = false; // Reset the flag
        return; // Exit to prevent click logic after a long tap
      }
      // check event target
      const target = e.target;
      if (target.classList.contains("payShopping")) {
        this.$root.$emit("showPayment", {
          type: "shopping",
          parcel: this.parcel,
        });
        logEventFA("generate_lead", { item_name: "shopping" });
        logEventFB("EVENT_NAME_SUBSCRIBE", { CONTENT_TYPE: "shopping" });
        return false;
      }

      this.$router.push({
        name: "ParcelInfo",
        params: { parcelId: this.parcel.id },
      });
      // return false;
      // http://jira.utec-group.com/browse/EASY-223
      // p_State_id=null - показывать карточку выкупа
      // p_State_id<>null and status_id=null - показывать карточку посылки
      // p_State_id<>null and status_id<>null - показывать карточку cargo
      // if (this.parcel.p_State_id && this.parcel.Status_id)
      //   this.$root.$emit(eventsRegister.showPopup, {
      //     body: this.parcel,
      //     header:
      //       lcl.parcelPu + " " + (this.parcel.p_pr_296 || this.parcel.id || ""),
      //     type: "cargo",
      //   });
      // else if (this.parcel.p_State_id && !this.parcel.Status_id)
      //   this.$root.$emit(eventsRegister.showPopup, {
      //     body: this.parcel,
      //     header:
      //       lcl.parcelPu + " " + (this.parcel.p_pr_296 || this.parcel.id || ""),
      //     type: "parcel",
      //   });
      // else if (!this.parcel.p_State_id)
      //   this.$root.$emit(eventsRegister.showPopup, {
      //     body: this.parcel,
      //     header: lcl.shoppingPu + " " + (this.parcel.pr_296 || this.parcel.id),
      //     type: "shopping",
      //   });
      // else console.warn("Case not determined: shopping/parcel/cargo?");
    },

    swipeHandler(direction: string) {
      if (this.parcelLoading) return false;
      if (direction === "left") this.cardIsSwiped = true;
      if (direction === "right") {
        if (!this.cardIsSwiped && this.isBuyout) this.onShare();
        this.cardIsSwiped = false;
      }
    },

    longTapHandler() {
      this.longTapOccurred = true;
      this.cardIsSwiped = true;
    },

    onCombine() {
      this.$root.$emit(eventsRegister.showPopup, {
        body: this.parcel,
        header: this.$t("combinePu"),
        type: "combine",
      });
    },

    onDelete(reason = "") {
      this.cardIsSwiped = false;
      this.parcelLoading = true;
      this.isDeclinedOpened = false;
      this.isActionPanelOpened = false;
      const requestData: any = {
        tmp_object_list: [
          {
            OBJECT_ID: this.parcel["id"],
            Class_id: 24800,
            ObjectDB_id: this.parcel["id"],
          },
        ],
      };
      createRequest(
        "deleteParcel",
        requestData,
        () => {
          this.$root.$emit(eventsRegister.updateParcels, () => {
            this.$root.$emit(eventsRegister.addMessage, {
              type: "success",
              message: this.$t("deletedParcels"),
              autoClose: true,
            });
            this.parcelLoading = false;
          });
        },
        () => {
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("serverError"),
          });
          this.parcelLoading = false;
          reason
            ? logEventFA("delete_card", {
                cardId: this.parcel["id"],
                customer_id: this.customer.ID,
              })
            : logEventFA("decline_card", {
                cardId: this.parcel["id"],
                reason,
                customer_id: this.customer.ID,
              });
        }
      );
    },
    onArchive() {
      this.cardIsSwiped = false;
      this.parcelLoading = true;
      this.isActionPanelOpened = false;
      const requestData: any = {
        tmp_object_list: [
          {
            OBJECT_ID: this.parcel["Package_id"],
            StateOld_id: this.parcel["p_State_id"],
            StateNew_id: -5003, // archive
          },
        ],
      };
      createRequest(
        "objState",
        requestData,
        () => {
          this.$root.$emit(eventsRegister.updateParcels, () => {
            this.$root.$emit(eventsRegister.addMessage, {
              type: "success",
              message: this.$t("parcelMovedToArchivePp"),
              autoClose: true,
            });
            this.parcelLoading = false;
          });
        },
        () => {
          // notAuthCallback
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("serverError"),
          });
          this.parcelLoading = false;
        }
      );
    },
    onRepeat() {
      this.cardIsSwiped = false;
      this.parcelLoading = true;
      this.isActionPanelOpened = false;
      const requestData: any = {
        tmp_object_list: [
          {
            OBJECT_ID: -1,
            Class_id: 24640,
            ObjectDB_id: null,
          },
        ],
        tmp_object_property_scalar: [
          {
            OBJECT_ID: -1,
            Property_id: 475,
            VALUE: this.customer.ID,
            Oper: "I",
          },
          {
            OBJECT_ID: -1,
            Property_id: 520,
            VALUE: this.parcel.pr520,
            Oper: "I",
          }, // type
        ],
      };
      if (this.parcel.pr451)
        // link
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 451,
          VALUE: this.parcel.pr451,
          Oper: "I",
        });
      if (this.parcel.pr256)
        // qty
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 256,
          VALUE: this.parcel.pr256,
          Oper: "I",
        });
      if (this.parcel.pr275)
        // country
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 275,
          VALUE: this.parcel.pr275,
          Oper: "I",
        });
      // Optional data
      if (this.parcel.pr444)
        // Model
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 444,
          VALUE: this.parcel.pr444,
          Oper: this.parcel.pr444,
        });
      if (this.parcel.pr446)
        // Size
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 446,
          VALUE: this.parcel.pr446,
          Oper: "I",
        });
      if (this.parcel.pr445)
        // Color
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: 445,
          VALUE: this.parcel.pr445,
          Oper: "I",
        });
      if (this.parcel.pr_235)
        // note
        requestData["tmp_object_property_scalar"].push({
          OBJECT_ID: -1,
          Property_id: -235,
          VALUE: this.parcel.pr_235,
          Oper: "I",
        });

      // set product category (list or string)
      requestData["tmp_object_property_scalar"].push({
        OBJECT_ID: -1,
        Property_id: 474,
        VALUE: this.parcel.pr474,
        Oper: "I",
      });

      createRequest(
        "objEdit",
        requestData,
        () => {
          this.$root.$emit(eventsRegister.updateParcels, () => {
            this.$root.$emit(eventsRegister.addMessage, {
              type: "success",
              message: "Заказ создан!",
              autoClose: true,
            });
            this.parcelLoading = false;
          });
        },
        () => {
          // notAuthCallback
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message: this.$t("serverError"),
          });
          this.parcelLoading = false;
        } // error callback
      );
    },
    onShare() {
      this.cardIsSwiped = false;
      this.parcelLoading = true;
      this.isActionPanelOpened = false;

      const sharePlugin = window["plugins"] && window["plugins"].socialsharing;

      const onSuccess = () => {
        !sharePlugin &&
          this.$root.$emit(eventsRegister.addMessage, {
            type: "success",
            message: this.$t("copied"),
            autoClose: true,
          });

        logEventFA("share_product", {
          group_id: this.parcel.pr474,
          customer_id: this.customer.ID,
        });
      };

      generateDynamicLink(
        {
          orderId: this.parcel.iParcel_id,
          refferalCode: this.customer.pr_625,
          countryCode: this.customer.pr201,
        },
        (url) => {
          this.parcelLoading = false;

          if (!sharePlugin) {
            copyToClipboard(
              `${this.$t("buyoutSharedTitle", {
                // @ts-ignore
                val: this.refferalRate,
              })} \n${url}`,
              onSuccess
            );
            return false;
          }
          // @ts-ignore
          (sharePlugin as any).shareWithOptions(
            {
              message: `${this.$t("buyoutSharedTitle", {
                // @ts-ignore
                val: this.refferalRate,
              })} \n${url}`,
              chooserTitle: this.$t("refferalShareTitle"),
            },
            onSuccess
          );
        }
      );
    },
  },
});
