


































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";

import eventsRegister from "@/services/events-register";

import { sortedArObjsDate, logEventFA, logEventFB, initRatingApp } from "@/utils/utils";
import { svgShopping, svgParcel, plusSVG } from "@/services/svg";

import ParcelCard from "@/components/ParcelCard.vue";
import { mapActions, mapGetters } from "vuex";
import appState from "@/store/appstate";
import PushParcel from "@/components/common/PushParcel.vue";

let scrollFunc: any;

export default Vue.extend({
  components: { ParcelCard, PushParcel },
  data() {
    return {
      eventsRegister: eventsRegister,
      svgShopping,
      svgParcel,
      plusSVG,
      logEventFA,
      logEventFB,
      appstate: appState,

      areParcelsReady: false,
      parcels: [] as any[],
      parcelsArchive: [] as any[],

      visibleParcelsNum: 5,
      isArchiveActive: false,
      loadedPages: 0,

      isArchivePosAbsolute: true,
      isProcessing: false,

      isMultiBtnActive: false,
    };
  },

  mounted() {
    if (!this.parcels.length) this.distributeParcels();

    Vue.nextTick(() => {
      this.checkArchivePos();
    });

    // set scrool listener and load more parcels
    window.addEventListener("scroll", this.onScroll, false);
  },

  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },

  computed: {
    ...mapGetters("parcels", ["parcelList"]),
    ...mapGetters("user", ["user", "userId"]),
    ...mapGetters("root", ["isSettingBtnActive"]),
    visibleParcels(): any[] {
      return this.parcels.slice(0, this.visibleParcelsNum);
    },
    isHasCombineParcels(): any {
      const parcelsToCombine = this.parcels.filter(
        (p) => [5020].includes(p.p_State_id) || [5020].includes(p.State_id)
      );
      const parcelsCN = parcelsToCombine.filter((p) => p.pr_587 === "CN");
      const parcelsUSA = parcelsToCombine.filter((p) => p.pr_587 === "US");

      return {
        CN: parcelsCN.length >= 2,
        US: parcelsUSA.length >= 2,
      };
    },
  },

  methods: {
    ...mapActions("parcels", ["loadArchiveParcelList"]),
    distributeParcels() {
      // animate parcels on init loading data
      this.areParcelsReady = false;
      this.parcels = [];
      this.parcelsArchive = [];

      // iParcel_id - shopping id
      // Package_id - parcel id
      // State_id - shopping stateId
      // p_State_id - parcel stateId
      // STATE - это состояние выкупа, а p_STATE - состояние Package
      // StepN этап выкупа,  p_StepN - этап посылки
      // let parcels: any[] = []
      // let parcelsArchive: any[] = []
      const sorrtedParcels = sortedArObjsDate(
        this.parcelList,
        "pr_290"
      );
      sorrtedParcels.forEach((item: any) => {
        item.id = item.Package_id || item.iParcel_id;
        // if (item.State_id === 5024 && !item.p_State_id || item.p_State_id === 5031) {  // skip 5024 EASY-494, skip 5031 EASY-315
        if (
          item.State_id === 5035 ||
          item.p_State_id === 5035 ||
          item.State_id === -5003 ||
          item.p_State_id === -5003
        )
          // archive
          this.parcelsArchive.push(item);
        else this.parcels.push(item);
      });

      // if (isInit) {
      //     parcels.forEach((item: any, i: number)=>{
      //         // if (i < 3) { // sgow first 3 parcels wit animation
      //             setTimeout(()=>{
      //                 self.parcels.push(item)
      //             }, 200)
      //         // } else self.parcels.push(item)
      //         // TODO.optimize: slice parcels and push all by one
      //     })
      //     // set parcelsArchive
      //     setTimeout(()=>{
      //         self.parcelsArchive = parcelsArchive
      //     }, 200)
      // }
      // let self: any = this
      // setTimeout(()=>{
      // self.parcels = parcels
      // self.parcelsArchive = parcelsArchive

      // if (this.parcelsArchive.length && !this.parcels.length) this.isArchiveActive = true // show archive only if archive parcels exist
      this.areParcelsReady = true;
      // },200)

      // check parcelsEl height and set archiveEl position
      Vue.nextTick(() => {
        this.checkArchivePos();
      });
    },

    async onRateApp() {
      try {
        const res = await initRatingApp();
        console.log('onRateApp', res)
      } catch (error) {
        console.log('onRateApp Error', error)
      }
    },
    checkArchivePos() {
      // check parcelsEl height and set archiveEl position
      const parcelsEl: any = this.$refs.parcelsEl;
      if (parcelsEl) {
        const parcelsElBCR = parcelsEl.getBoundingClientRect();
        // check end of parcelsContainer (bottom part) visible in the screen
        const displayHeight = window.innerHeight;
        let marginBottom = 168; // Warn: this value can be changed
        if (this.appstate.platform === "ios") marginBottom = 180;
        if (parcelsElBCR.bottom < displayHeight - marginBottom)
          this.isArchivePosAbsolute = true;
        else this.isArchivePosAbsolute = false;
      } else this.isArchivePosAbsolute = false;
    },

    onScroll() {
      // Show next 5 parcels if parcelsList scrolled to bottom
      if (!this.parcels.length) return false; // only if parcels exist

      // Clear previous timeout when new scroll active
      window.clearTimeout(scrollFunc);

      // catch end of scrolling and check parcelsContainer position
      // Set a timeout to run after scrolling ends
      scrollFunc = setTimeout(() => {
        // Run the callback
        const parcelsEl: any = this.$refs.parcelsEl;
        const parcelsElBCR = parcelsEl.getBoundingClientRect();
        // check end of parcelsContainer (bottom part) visible in the screen
        const displayHeight = window.innerHeight;
        const marginBottom = 70;
        if (parcelsElBCR.bottom < displayHeight - marginBottom) {
          // parcelsContainer scroller to end
          if (this.parcels.length - this.visibleParcelsNum > 0) {
            // hidden parcels exist
            this.visibleParcelsNum += 5;
            this.loadedPages++;
          }

          // else // delete scroll listener - no hidden parcels
          // window.removeEventListener('scroll', this.onScroll)
        }
      }, 100);
    },

    openBuyout() {
      this.$router.push('add-buyout')
      this.isMultiBtnActive = false;
      logEventFA("level_up", { customer_id: this.userId });
      logEventFB("EVENT_NAME_UNLOCKED_ACHIEVEMENT");
    },

    openDelivery() {
      this.$router.push('add-delivery')
      this.isMultiBtnActive = false;
      logEventFA("tutorial_complete", {
        customer_id: this.userId,
      });
      logEventFB("EVENT_NAME_COMPLETED_TUTORIAL");
    },
    async loadArchive() {
      this.isProcessing = true;
      //@ts-ignore
      await this.loadArchiveParcelList();
      this.isProcessing = false;
    },

    // addUser() {
    //   sendFirebaseDB({
    //     customer: 2823755,
    //     parcel: "EZ902962518-1"
    //   }, () => {})
    //   sendFirebaseDB({
    //     customer: 2823755,
    //     parcel: "EZ902962518-2"
    //   }, () => {})
    //   sendFirebaseDB({
    //     customer: 2823755,
    //     parcel: "EZ902962518-3"
    //   }, () => {})
    //   sendFirebaseDB({
    //     customer: 2823755,
    //     parcel: "EZ902962518-4"
    //   }, () => {})
    // }
  },
  watch: {
    async parcelList() {
      this.distributeParcels()
    },
  },
});
